<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Editar</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
              <el-form label-position="top" label-width="100px" :model="form">
              <el-form-item label="Título" size="mini">
                <ValidationProvider name="Titulo" rules="required|min:5|max:60" v-slot="{ errors }">
                  <el-input v-model="form.title"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Imagen">
                <div v-if="form.image">
                  <el-image :src="$urlGlobalApi +form.image">
                    <div slot="placeholder" class="image-slot">
                      Loading<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
                <el-upload
                  drag
                  action=""
                  name="image"
                  :http-request="postImage"
                  :on-success="handleAvatarSuccess"
                  multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                  <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb (300 x 300)</div>
                </el-upload>
              </el-form-item>
              <el-form-item label="Orden" size="mini">
                <ValidationProvider name="order" rules="required" v-slot="{ errors }">
                  <el-input-number v-model="form.order" controls-position="right" :min="0"></el-input-number>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Estatus*" class="size-tips">
                <ValidationProvider name="estatus" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.status"
                  filterable
                  placeholder="Estatus">
                    <el-option
                      v-for="item in optionsStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select><br>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>
                <span>Si el estatus es <strong>No</strong> el artículo no estara visible dentro del sitio</span>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onUpdate"
                type="primary"
                size="mini"
                :disabled="invalid"
                :loading="loading"
                icon="el-icon-upload2">
                Actualizar</el-button>
              </el-form-item>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { getSections, updateSections } from '@/api/sections'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        title: '',
        image: '',
        order: 0,
        status: true
      },
      optionsStatus: [{
        value: true,
        label: 'Si'
      }, {
        value: false,
        label: 'No'
      }],
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await getSections(this.$route.params._id)
        .then((response) => {
          this.form = response.data
          console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onUpdate () {
      await updateSections(this.$route.params._id, this.form)
        .then((response) => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Actualizado con exito!',
            type: 'success'
          })
          this.$router.push({ name: 'sections' })
        })
        .catch(this.responseCatch)
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('folder', 'images')
      formData.append('width', '300')
      formData.append('height', '400')
      formData.append('title', 'imagen-avigrupo')
      formData.append('alt', 'imagen-avigrupo')
      uploadImageFile(formData).then((response) => {
        console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.image = res
      console.log(this.form.image)
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    }
  }
}
</script>
